import { Layout } from '../../components/Layout';
import React from 'react';
import { MessageDiv, StyledArticle } from '../../styles';
import { StyledH2 } from '../../styles/common';

import { SEO } from '../../components/SEO';

export const Head = () => (
	<SEO 
	title='70 lat bydgoskich autobusów'
	description='70 lat bydgoskich autobusów.'
	/>);

const Lat70Page = () => (
	<Layout>
		<StyledArticle>
			<StyledH2 as="h1" color="info">
				70 lat bydgoskich autobusów
			</StyledH2>
			<MessageDiv>
				<p>
					Pierwsze autobusy miejskie wyjechały na ulice Bydgoszczy 16
					października 1936 roku. Wtedy nikt nie wróżył im wielkiej przyszłości.
					Podstawowym środkiem transportu do lat 70-tych był tramwaj.
				</p>
				<p>
					W latach międzywojennych Bydgoszcz zaczęła się rozbudowywać we
					wszystkich kierunkach. Najwięcej obiektów użyteczności publicznej i
					domów powstało w kierunku północno-zachodnim. Pod koniec lat 30-tych.
					komunikacja tramwajowa przestała nadążać za pasażerami. Wybudowano co
					prawda w latach 1936-37 nowy odcinek linii na Bielawy, ale takie
					dzielnice jak Bocianowo i okolice ulicy Gdańskiej na północ od torów
					kolejowych były nadal pozbawione komunikacji. Dlatego zapadła decyzja
					o uruchomieniu autobusów miejskich.
				</p>
				<p>
					W 1936 roku zakupiono 4 kompletne podwozia z silnikami marki Polski
					Fiat. Karoserie i wyposażenie wykonano w Bydgoszczy. W ten sposób
					powstały autobusy, które zabierały po 16 pasażerów. Wszystkie pojazdy
					pomalowano na niebiesko. Pierwsza linia wiodła od dworca Bydgoszcz
					Główna ulicami Warszawską, Sobieskiego, Hetmańską, Świętojańską i
					Gdańską aż do Szkoły Podchorążych. Bilety kosztowały 20 groszy. Rok
					później powstały dwie kolejne linie. Jedna łączyła nowy dworzec
					autobusów podmiejskich na pl. Kościeleckich z ulicą Toruńską. Po kilku
					miesiącach przedłużono ją przez ulicę Sporną do dworca Bydgoszcz
					Wschód. Druga linia obsługiwała Szwederowo. Jej trasa wiodła od
					Brzozowej przez Kujawską, Wały Jagiellońskie, Nowy Rynek, Podgórną,
					Orlą, Piękną i Szubińska na Żwirki i Wigury.
				</p>
				<p>
					Pod koniec sierpnia 1939 roku komunikację autobusową zawieszono, a
					autobusy wraz z wybuchem wojny przekazano wojsku. Niemcy jednak w dniu
					16 maja 1943 roku ponownie uruchomili ten środek transportu.
					Zaopatrzyli miasto w 5 autobusów marki Büssing - NAG - Trambusse.
					Pojazdy te były napędzane gazem świetlnym. Zbiornik z paliwem
					znajdował się na dachu. Przez to wyglądem przypominały londyńskie
					&apos;piętrusy&apos;. W sumie Niemcy utworzyli trzy linie autobusowe.
					Pierwsza łączyła Osiedle Gdańskie z Lotniskiem, druga Dworzec Główny
					ze Szpitalem Miejskim przy ul. Jurasza, a trzecia pl. Kościeleckich z
					Glinkami. Pod koniec wojny ilość wozów wzrosła do 10. 4 z nich zostały
					zabrane do Niemiec.
				</p>
				<p>
					Warto jednak dodać, że w 1944 roku niemieckie władze chciały zastąpić
					autobusy kursujące między Gdańską, a Szubińską trolejbusami.
					Rozwieszono nawet trakcje i sprowadzono 4 trolejbusy z Mannheim.
					Wkroczenie wojsk polskich i sowieckich pokrzyżowały jednak te plany.
				</p>
				<p>
					Już po zakończeniu działań wojennych komunikacja autobusowa nad Brdą
					ponownie ożyła. Nastąpiło to 3 kwietnia 1945 roku. Pierwsze linie
					połączyły Gazownię przy ul. Jagiellońskiej z Osiedlem Gdańskim oraz
					Dworzec ze Szpitalem Miejskim. W 1946 roku miejskie autobusy po raz
					pierwszy zaczęły docierać do Fordonu. Do 1973 roku było to jednak
					wciąż samodzielne miasto. Jednak po kilku miesiącach połączenie to
					zawieszono.
				</p>
				<div>
					W 1955 roku istniało już 5 połączeń autobusowych:
					<ol className="mt10">
						<li>Czyżkówko - Łęgnowo</li>
						<li>Osiedle Gdańskie - Lotnisko</li>
						<li>Bernardyńska - Solna</li>
						<li>Dworzec - Jachcice</li>
						<li>Szpital Jurasza - Ks. Skorupki</li>
					</ol>
				</div>
				<p>
					W maju i czerwcu 1955 roku wszystkie Büssingi skierowano do Gdańska. W
					zamian Bydgoszcz otrzymała 7 nowych, węgierskich autobusów marki
					&apos;Ikarus - 60&apos;. Z kolei w październiku 1956 roku dotarły
					pierwsze wyprodukowane po wojnie w Polsce autobusy &apos;Star N
					52&apos; zwane popularnie &apos;Stonką&apos;. Pierwszego roku dostawa
					wynosiła 12 sztuk.
				</p>
				<p>
					W 1957 roku do miasta trafiło kolejnych 8 szt. &apos;stonek&apos;, a
					rok później nowocześniejsze &apos;Sany H 01B&apos;
				</p>
				<p>
					W drugiej połowie lat 50-tych miejskie autobusy zaczęły dojeżdżać do
					Brdyujścia, Smukały i na Prądy. W 1961 roku wznowiono połączenie do
					Fordonu. Była to pierwsza linia pospieszna w Bydgoszczy. Rok później
					autobusy zaczęły obsługiwać Biedaszkowo i Osową Górę. W 1962 roku
					zmieniono numerację wszystkich linii. Linie zwykłe oznaczono numerami
					od 51 wzwyż, a pospieszne od 101 wzwyż. W połowie lat 60. tabor
					wzbogacił się o 46 nowych Sanów H-100 i 53 Jelczy 706 RTO/272 zwanych
					popularnie &apos;ogórkami&apos;. Dzięki tym wielkopojemnym autobusom
					komunikacja miejska wreszcie stanęła na nogi. W 1966 wraz z dostawami
					Jelczy wybudowano także nową zajezdnię autobusową na ul. K. Szajnochy.
				</p>
				<p>
					W 1973 roku wraz z przyłączeniem Fordonu do Bydgoszczy uruchomiono
					zwykłą linię nr 65. Połączyła ona fordoński rynek z ulicą Wyścigową.
					Po kilku miesiącach przedłużono ją na osiedla Leśne. W tym samym
					czasie niektóre kursy linii 54 skierowano na Piaski. W latach 70.
					Bydgoszcz otrzymała też wiele nowych autobusów. Na ulicach pojawiły
					się Jelcze 272, Jelcze 043, Jelcze PR 110, Autosany H 9-1 i Autosany H
					9-35. W 1987 roku wybudowano także drugą zajezdnię na ul.
					Inowrocławskiej 11.
				</p>
				<p>
					W latach 80. komunikacja miejska zaczęła obsługiwać nowopowstające
					osiedla mieszkaniowe w Nowym Fordonie. Ułatwiły to dostawy węgierskich
					Ikarusów, które na wiele lat stały się dominującym środkiem w
					komunikacji Bydgoszczy. W 1983 roku po ulicach Bydgoszczy jeździło
					prawie 300 autobusów. W 1985 roku zakończono także budowę zajezdni
					autobusowej na Toruńskiej. Przygotowano tam 80 miejsc postojowych
					wyłącznie dla przegubowych Ikarusów.
				</p>
				<p>
					W pierwszej połowie lat 90-tych tabor był mocno zniszczony. Przez
					kilka lat na bydgoskich ulicach kursowały używane pojazdy z Niemiec.
					Jednak po kilku latach użytkowania zostały skasowane. Sytuacja uległa
					poprawie w 1997 roku, kiedy Miejskie Zakłady Komunikacyjne otrzymały
					58 nowoczesnych autobusów Volvo. Pojazdy zaczęto wyposażać również w
					radiotelefony i urządzenia głośnomówiące do zapowiadania trasy i
					przystanków. W latach 90-tych miejskiemu przedsiębiorstwu wyrosła
					także konkurencja. Niektóre linie zaczęli obsługiwać inni przewoźnicy.
					Powstało też kilkanaście połączeń mikrobusowych.
				</p>
				<div>
					Dziś największym przewoźnikiem w naszym mieście są nadal Miejskie
					Zakłady Komunikacyjne Sp. z o.o. w Bydgoszczy, które obsługuję 32
					linie autobusowe. Posiadają one 199 autobusów następujących marek:
					<ul>
						<li>Ikarus - 280 (przegubowy) 15 szt.</li>
						<li>Ikarus - 280.70 (przegubowy) 31 szt.</li>
						<li>Jelcz M 181 MB (przegubowy) 7 szt.</li>
						<li>Jelcz M 181 M/1 (przegubowy) 8 szt.</li>
						<li>Jelcz M 121 MB 1 szt.</li>
						<li>Jelcz LO 90 M 5 szt.</li>
						<li>Volvo B 10 BLE 41 szt.</li>
						<li>Volvo B 10 BLE 6X2 11 szt.</li>
						<li>Volvo B 10 L 7 szt.</li>
						<li>Volvo B 10 LA (przegubowy) 15 szt.</li>
						<li>Volvo B 10 MA (przegubowy) 15 szt.</li>
						<li>Volvo 7000 A (przegubowy) 4 szt.</li>
						<li>Man NL 223 7 szt.</li>
						<li>Man NL 313 (przegubowy) 9 szt.</li>
						<li>Mercedes Conecto 0450 23 szt.</li>
					</ul>
				</div>
			</MessageDiv>
		</StyledArticle>
	</Layout>
);

export default Lat70Page;
